// Dashboard
const Dashboard = () => import("@/pages/Dashboard/Dashboard")

// Auth
const Login = () => import("@/pages/Auth/Login")

// Collections
const Collection = () => import(/* webpackChunkName: "collections" */ "@/pages/Collections/Collection")
const CollectionFolder = () => import(/* webpackChunkName: "collections" */ "@/pages/Collections/CollectionFolder")

// Forms
const Forms = () => import("@/pages/Forms/Forms")

// Analytics
const Analytics = () => import("@/pages/Analytics/Analytics")
const ContentAnalytics = () => import("@/pages/Analytics/ContentAnalytics")
const TradeAnalytics = () => import("@/pages/Analytics/TradeAnalytics")
const InspiresCollectionAnalytics = () => import("@/pages/Analytics/InspiresCollectionAnalytics")
const InspiresUserAnalytics = () => import("@/pages/Analytics/InspiresUserAnalytics")

// Content
const Content = () => import("@/pages/Content/Content")
const ExclusiveContent = () => import("@/pages/Content/ExclusiveContent")
const UserUploads = () => import("@/pages/Content/UserUploads")
const AddEditContent = () => import("@/pages/Content/AddEditContent")

// Errors
const PageNotFound = () => import("@/pages/Errors/PageNotFound")

// Settings
const Settings = () => import(/* webpackChunkName: "settings" */ "@/pages/Settings/Settings")
const AccountSettings = () => import(/* webpackChunkName: "settings" */ "@/pages/Settings/AccountSettings")
const LandingPageSettings = () => import(/* webpackChunkName: "settings" */ "@/pages/Settings/LandingPageSettings")
const ApiSettings = () => import(/* webpackChunkName: "settings" */ "@/pages/Settings/ApiSettings")
const AnalyticsSettings = () => import(/* webpackChunkName: "settings" */ "@/pages/Settings/AnalyticsSettings")
const SecuritySettings = () => import(/* webpackChunkName: "settings" */ "@/pages/Settings/SecuritySettings")

export {
  // Dashboard
  Dashboard,
  // Auth
  Login,
  // Collections
  Collection,
  CollectionFolder,
  // Forms
  Forms,
  // Analytics
  Analytics,
  ContentAnalytics,
  TradeAnalytics,
  InspiresCollectionAnalytics,
  InspiresUserAnalytics,
  // Content
  Content,
  ExclusiveContent,
  UserUploads,
  AddEditContent,
  // Errors
  PageNotFound,
  // Settings
  Settings,
  AccountSettings,
  LandingPageSettings,
  ApiSettings,
  AnalyticsSettings,
  SecuritySettings
}
